import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppInsightsService } from '../app-insights.service';


@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  constructor(
    private appInsights: AppInsightsService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.appInsights.logTimeStamp(`${req.url}`, 'api');

    return next.handle(req).pipe(
      map((event) => {
        if (event instanceof HttpResponse) {
          this.appInsights.logTimeStamp(`${req.url}`, 'api', true);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this.appInsights.logError(error);
        throw error;
      })
    );
  }
}