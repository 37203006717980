import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppInsightsService {
  appInsights: ApplicationInsights;

  constructor() {
      this.appInsights = new ApplicationInsights({
          config: {
              instrumentationKey: environment.appInsights.instrumentationKey,
              enableAutoRouteTracking: true, // option to log all route changes
          },
      });
      this.appInsights.loadAppInsights();
  }

  log(name: string, properties?: { [key: string]: any }) {
      environment.production ? noop() : console.info(name, properties);
      this.appInsights.trackEvent({ name: name }, properties);
  }

  logInfo(message: any, ...rest: any) {
      environment.production ? noop() : console.info(message, ...rest);
  }

  logError(error: any, ...rest: any) {
      environment.production ? this.appInsights.trackException(error, rest) : console.error(error, ...rest);
  }

  logDebug(message: any, ...rest: any) {
      environment.production ? noop() : console.debug(message, ...rest);
  }

  logTimeStamp(label: string, type: string = '', end: boolean = false) {
      environment.production
          ? this.appInsights.trackTrace({ message: label }, { type: type, end: end })
          : (() => {
              const logLabel = `[Smile Health] ${type ? type + ' ' : ''}${label}`;
              if (end) {
                  console.timeEnd(logLabel);
              } else {
                  console.time(logLabel);
              }
              console.timeStamp();
          })();
  }
}

function noop() { }
