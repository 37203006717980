import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EventsManager } from 'src/app/events/events.manager';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-purchase-widget',
  templateUrl: './purchase-widget.component.html',
  styleUrl: './purchase-widget.component.scss'
})
export class PurchaseWidgetComponent implements OnInit, AfterViewInit {
  urlSafe: SafeResourceUrl = '';
  widgetUrl: string = environment.widgetUrl;
  constructor(public em: EventsManager,private sanitizer: DomSanitizer) {

  }

  ngOnInit() {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.widgetUrl+"?organization="+this.em.orgId+"&practice="+this.em.officeInfo?.practice.id+"&branding=generic" + "&structure=generic");
  }

  ngAfterViewInit(){
    window.scroll(0,0);
  }
}
