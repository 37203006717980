<div class="modal fade show d-block modal-wf-lg">
    <div class="modal-dialog modal-dialog">
      <div class="modal-content modal-container">
        <div class="modal-body">
            <div class="container">
                <div class="row header-layout">
                    <div class="col-10 header-text">
                    Invite a Friend
                    </div>
                    <div class="col-1 close-img">
                        <img src="assets/images/close.png" alt="Close" (click)="modal.close('closed')" id="closeButton"/>
                    </div>
                </div>
                <div id="sendInvite" *ngIf="!successMessage">
                    <form id="sendInviteForm" [formGroup]="sendInviteForm"  name="sendInviteForm" autocomplete="off">
                        <div class="row body-layout">
                            <div class="col-12 subheader-layout">
                                <div class="row subheader-text">
                                    Send this page to a friend via text message
                                </div>
                            </div>
                            <div class="col-12 subheader-layout" >
                                <label for="phoneNumber" class="label-text">Phone Number</label>
                                <input type="text" class="text-input" name="phoneNumber" id="phoneNumber" formControlName="phoneNumber" mask="(000)000-0000" placeholder="(555)555-5555" >
                                <div *ngIf="sendInviteForm.get('phoneNumber')?.invalid && (sendInviteForm.get('phoneNumber')?.dirty || sendInviteForm.get('phoneNumber')?.touched)" class="error-message">
                                  Phone Number is required or is invalid
                                </div>
                            </div>
                            <div class="col-12 lower-layout">
                              <re-captcha id="recaptcha" class="recaptcha-layout" name="recaptcha" #recaptcha="ngModel" [(ngModel)]="token" required
                              [ngModelOptions]="{standalone: true}" siteKey="{{captcha}}" [class.is-invalid]="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)"></re-captcha>
                              <div *ngIf="recaptcha.invalid && (recaptcha.dirty || recaptcha.touched)" class="error-message">
                                  <div *ngIf="recaptcha.errors?.['required']">This field is required.</div>
                              </div>
                            </div>
                            <div class="col-12 lower-layout">
                              <button [disabled]="sendInviteForm.invalid" class="btn-send" (click)="sendInvitation()" id="sendButton">Send</button>
                            </div>
                        </div>
                    </form>
                  <div *ngIf="showCaptchaError" class="col-12 col-12 error-message">
                    Please complete the Captcha to send the invitation.
                  </div>
                </div>
                <div class="success-layout" id="successMessage" *ngIf="successMessage">
                    <div class="success-image">
                        <img src="assets/images/success_withcircle.png" alt="Success"/>
                    </div>
                    <div class="success-message">
                        Invitation Sent Successfully!
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

a
