<div class="container">
  <div class="row mt-3 mb-4"  *ngIf="!successMessage">
    <div class="col-12 header-text">
      Member Support
    </div>
    <div class="col-12 sub-header-text">
      We are here to support you. Our contact information and hours are below:
    </div>
  </div>
  <div class="row mb-5" *ngIf="!successMessage">
    <div class="col-lg-8 col-12 mb-5">
      <div class="card-box">
        <div class="row">
          <div class="col-12">
            <span class="support-header-text">Member Information</span>
            <hr class="m-3">
          </div>
          <form id="supportRequest" [formGroup]="supportRequestForm"  name="sendInviteForm" novalidate autocomplete="off">
            <div class="row">
              <div class="col-lg-6 col-12">
                <label for="firstName" class="label-text">First Name</label>
                <input type="text" class="text-input" name="firstName" id="firstName" formControlName="firstName" required>
                <div *ngIf="supportRequestForm.get('firstName')?.touched" class="error-message">
                  <span *ngIf="supportRequestForm.get('firstName')?.hasError('required')">First Name is required</span>
                  <span *ngIf="supportRequestForm.get('firstName')?.hasError('invalidFirstName')">First Name is invalid</span>
                </div>
              </div>
              <div class="col-lg-6 col-12">
                <label for="lastName" class="label-text">Last Name</label>
                <input type="text" class="text-input" name="lastName" id="lastName" formControlName="lastName" required>
                <div *ngIf="supportRequestForm.get('lastName')?.touched" class="error-message">
                  <span *ngIf="supportRequestForm.get('lastName')?.hasError('required')">Last Name is required</span>
                <span *ngIf="supportRequestForm.get('lastName')?.hasError('invalidLastName')">Last Name is invalid</span>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-lg-6 col-12">
                <label for="dob" class="label-text">Date of Birth</label>
                <input type="text" class="text-input" name="dob" formControlName="dob" id="dob" (keyup)="dateFormat($event)" placeholder="MM/DD/YYYY" maxlength="10">
                <div *ngIf="supportRequestForm.get('dob')?.touched" class="error-message">
                <span *ngIf="supportRequestForm.get('dob')?.hasError('invalidDate')">Date of Birth is invalid</span>
                </div>
              </div>
              <div class="col-lg-6 col-12">
                <label for="emailAddress" class="label-text">Email</label>
                <input type="text" class="text-input" name="emailAddress" id="emailAddress" formControlName="emailAddress" required>
                <div *ngIf="supportRequestForm.get('emailAddress')?.touched" class="error-message">
                  <span *ngIf="supportRequestForm.get('emailAddress')?.hasError('required')">Email Address is required</span>
                <span *ngIf="supportRequestForm.get('emailAddress')?.hasError('invalidEmail')">Email Address is invalid</span>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12">
                <label for="questionCategory" class="drop-down-text-text">Select Question</label>
                <select name="questionCategory" id="questionCategory" class="select" formControlName ="question">
                  <option value="null">Choose A Question</option>
                  <option value="Billing">Billing</option>
                  <option value="AccountQuestion">Account Question</option>
                  <option value="Eligibility">Eligibility</option>
                  <option value="PlanAcceptance">Plan Acceptance</option>
                  <option value="AddingAMember">Adding A Member</option>
                </select>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12">
                <label for="message" class="drop-down-text-text">Message</label>
                <textarea class="text-area" rows="5" name="message" style="width:100%" id="message" formControlName="message">
                </textarea>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12">
                <span class="btn-container" style="width: 100%"><button class="submit-btn" [disabled]="supportRequestForm.invalid"
                  (click)="sendSupportEmail()" id="supportButton">Submit</button></span>
              </div>
            </div>
          </form>
        </div>

      </div>

    </div>
    <div class="col-lg-4 col-12 mb-5">
      <div class="row">
        <div class="col-12 card-box mb-2">
          <div class="col-12">
            <span class="support-header-text">Contact Information</span>
            <hr class="mt-2">
          </div>
          <div class="col-12">
            <div class="contact-text mb-4">{{dataservice.PhoneNumber}}</div>
            <div class="contact-text mt-4 mb-4">{{dataservice.EmailAddress}}</div>
            <div class="contact-text mt-4 mb-4">{{dataservice.WeekdayHours}}</div>
            <div class="contact-text mt-4 mb-4">{{dataservice.MailingAddress}}</div>
          </div>
        </div>
        <div class="col-12 card-box">
          <div class="row">
            <div class="col-12 col-lg-12">
              <span class="support-header-text">Member Fee Schedule</span>
              <hr class="mt-2">
            </div>
            <div class="col-12 col-lg-12">
              <div *ngFor="let plan of plans.benefitPlanTemplates" class="m-3"><a class="link-text mb-4" (click)="setFeeScheduleInfo(plan)" routerLink="/feeSchedule" id="feeScheduleLink">{{plan.name}}</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="success-layout" id="successMessage" *ngIf="successMessage">
    <div class="success-image">
        <img src="assets/images/success_withcircle.png" alt="Success"/>
    </div>
    <div class="success-message">
       <h1> Message Submitted Successfully!</h1>
    </div>
    <div class="success-message-content">
      <h5>
        Your message has been received, and our team will reach out to you shortly. Thank you for contacting us!
        </h5>
   </div>
</div>
</div>
