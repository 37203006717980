import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {LandingComponent} from "./components/landing/landing.component";
import {EventsManager} from "./events/events.manager";
import {PlansService} from "./services/plans.service";
import * as _ from "underscore";
import { DataService } from './services/data.service';
import { SupportInformation } from './models/SupportInformation.model';
import {environment} from "../environments/environment";
import { LegalModel } from './models/legal.model';
import { ErrorService } from './services/error.service';
import { MainPageDetailModel } from './models/plans.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  office?: MainPageDetailModel = undefined;
  geoSaved:boolean = false;
  showSubHeader: boolean = true;
  legal:any = {};
  adminBy:string = '';

  constructor(public modalService: NgbModal,
     private route: ActivatedRoute,
     private em: EventsManager,
     private service: PlansService,
     private dataService: DataService,
     private errorService: ErrorService) {

  }
  ngOnInit() {
    let host:string = window.location.host;
    this.em.orgId = '00000000-0000-0000-0000-000000000000';
    if(!this.geoSaved && window.location.pathname === '/'){
      this.openGeoModal();
    } else if(window.location.pathname.split('/')[2] && window.location.pathname.split('/')[2].length > 0 && window.location.pathname.split('/')[1] === 'office' ){
      this.getOffice(window.location.pathname.split('/')[2]);
    } else if(window.location.pathname.split('/')[1] && window.location.pathname.split('/')[1].length > 0 && window.location.pathname.split('/')[1] !== 'purchase'){
      let args = window.location.pathname.split('/');
      if(args[2] && args[2].length > 0) {
        this.getOfficeByVanity(args[1],args[2]);
      }
      else if(args[1] !== 'purchase')
      {
        this.getOfficeByVanity('',args[1]);
      }

    } else {
      let off:any = JSON.parse(sessionStorage['office']);
      this.getOffice(off.practiceId);
    }
    this.em.showHideSubheader.subscribe((data:boolean) =>{
      this.showSubHeader = data;
    })

    window.addEventListener("message", this.receiveMessage, false);
  }

  receiveMessage = (event: any) => {
    const message = event.data.message;
    if(message === 'action_scroll'){
      window.scrollTo(0,0);
    }


    if (message === 'action_resize') {
      const newHeight = event.data.height;
      const purchaseWidget = document.getElementById('purchase-widget');
      if (purchaseWidget) {
        purchaseWidget.style.height = `${newHeight + 10}px`;
      }
    }
  }

  openGeoModal():void {
    const modalRef: NgbModalRef = this.modalService.open(LandingComponent);
    modalRef.result.then(
      (result: any) => {
        this.getOffice(result);
        this.closeModal();
      },
      error => {
        console.log('dia error: ', error);
      }
    )
  }

  closeModal() {
    // need stuff here
    this.modalService.dismissAll();

  }

  setDisplayInfo(office:any){
    this.office = office;
    this.showSubHeader = true;
    this.em.subHeaderShowHide(true);
    this.getLegalLanguage();
  }

  getOffice(office:string){
    this.service.getOffice(office).subscribe(
      (result:MainPageDetailModel) => {
        this.office = result;
        this.setAdminBy(this.office.benefitPlanTemplates[0]?.isAdminBy);
        this.em.docOrgId = this.office.organizationId;
        this.setDisplayInfo(this.office);
        this.em.setOfficeInfo(this.office);
        this.setSupportInfo(this.office);
      },
      (error) => {
        if (error.status === 400 && Array.isArray(error.error)) {
          this.processValidationError(error.error);
        } else {
          // Handle other types of errors
         // console.log('General Error: ', error);
        }
      }
    );
  }

  processValidationError(validationErrors: any) {
   // console.log('Validation Errors: ', validationErrors);
    if (Array.isArray(validationErrors) && validationErrors.length > 0) {
      this.errorService.showError(validationErrors[0].message);
    }
  }

  getOfficeByVanity(orgVanity:string, practiceVanity:string){
    this.service.getOfficeByVanityName(orgVanity,practiceVanity).subscribe(
      (result:MainPageDetailModel) => {
        console.log('office: ', result);
        this.office = result;
        if (this.office?.benefitPlanTemplates) {
          this.setAdminBy(this.office?.benefitPlanTemplates[0]?.isAdminBy);
        }
        this.em.docOrgId = this.office?.organizationId ?? '';
        this.setDisplayInfo(this.office);
        this.em.setOfficeInfo(this.office);
        this.setSupportInfo(this.office);
     //   console.log('office: ',this.office);
      },
      (error) => {
     //   console.error('Error occurred: ', error);

        if (error.status === 400 && Array.isArray(error.error)) {
          console.log('Validation error: ', error.error);
        } else {
          // Handle other types of errors
          console.log('General Error: ', error);
        }
      }
    )
  }

  setSupportInfo(office?:MainPageDetailModel){

    let supportInfo: SupportInformation = {
        PhoneNumber: this.office?.telephoneNumber ?? '',
        MailingAddress: this.office?.address ?? '',
        EmailAddress: this.office?.emailSupport ?? '',
        WeekdayHours: this.office?.weekdayHours ?? '',
        WeekendHours: this.office?.weekendHours ?? ''
    }
    this.dataService.setSupportInfo(supportInfo);
  }

  getLegalLanguage(){
    this.service.getLegalLanguage().subscribe(
      (result:LegalModel) => {
        this.legal = result;
        if(this.adminBy == ''){
          this.adminBy = 'wellfit';
        }
        this.em.setLegalLanguage(_.findWhere(this.legal,{adminBy:this.adminBy}));
      }
    )
  }

  setAdminBy(who:any) {
    if(who.length == 1){
      switch(who){
        case '0':
          this.adminBy = 'wellfit';
          break;
        case '1':
          this.adminBy = 'careington';
          break;
      }
    } else {
      this.adminBy = this.office?.benefitPlanTemplates[0]?.isAdminBy ?? 'wellfit';
    }
  }
}
