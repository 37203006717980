<div class="footer">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <img src="assets/images/wellfit-plans%201.svg" alt="Wellfit"/>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-8">
        <div class="company-name">
          Wellfit Plans, LLC.
        </div>
        <div class="company-address">
          (A division of Wellfit Technologies, Inc.) <br/>
          PO Box 140309, Irving, TX 75014-0309
        </div>
        <div class="copyright">
          Copyright © {{year}} Wellfit Technologies, Inc.
        </div>
      </div>
      <div class="col-12 col-lg-4 mt-2 mt-sm-1 text-center">
        <a class="provider-login" href="{{environment.providerPortalLink}}" target="_blank" id="providerLink">Are You A Provider? <img src="assets/images/external-link-icon.svg" alt="" class="link-icon"></a> <br>
        <a class="footer-link" routerLink="support" id="supportLink">Support</a> <span class="footer-link-separator"> | </span> <a class="footer-link" href="https://wellfit.com/wellfits-privacy-policy/" target="_blank" id="privacyLink">Privacy Policy</a> <span class="footer-link-separator"> | </span> <a class="footer-link" href="https://wellfit.com/wellfits-terms-of-service/" target="_blank" id="termsOfServiceLink">Terms of Service</a>
        <span class="footer-link-separator"> | </span> <a class="footer-link" href="https://wellfit.com/wellfits-california-consumer-privacy-policy/" target="_blank" id="ccpaLink">California Consumer Privacy Policy</a>
      </div>
    </div>
  </div>

</div>
